// CROPPER JS STYLING
// .cropper-view-box {
// 	border-radius: 50%;
// }
// todo Future Feature. WE will need to make this apply only when the profile picture is being selected.a dafsjifdasolifajsd
.cropper-face {
    background-color: inherit !important;
}

.cropper-dashed {
    display: none;
}