@import "../../assets/styles/colors.scss";
@import "@angular/material/theming";
.gh-button {
    &.gh-button-round {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.gh-button-round-smaller {
            height: 40px;
            width: 40px;
        }
        &.gh-button-round-z12 {
            @include mat-elevation(12);
        }
        &.gh-button-round-z16 {
            @include mat-elevation(16);
        }
    }
    &.gh-button-success {
        background: $gh-color-success;
        color: white;
    }
    &.gh-button-success:hover {
        background: $gh-color-success-darker;
        color: white;
    }
    &.gh-button-warning {
        background: $gh-color-warning;
        color: white;
    }
    &.gh-button-warning:hover {
        background: $gh-color-warning-darker;
        color: white;
    }
    &.gh-button-edit {
        background: $gh-color-edit;
        color: white;
    }
    &.gh-button-edit:hover {
        background: $gh-color-edit-darker;
        color: white;
    }
    &.gh-button-cancel {
        background: $gh-table-stripe-gray;
        color: $gh-color-secondary;
    }
    &.gh-button-cancel:hover {
        background: $gh-table-stripe-gray;
        color: $gh-color-secondary-darker;
    }
    &.gh-button-primary {
        // background: $gh-color-primary;
        // color: white;
        background: $gh-color-primary;
        color: white;
        &.primary-animation:hover,
        .primary-animation:active {
            background-color: $gh-color-primary-most-darker !important;
            transition: all 0.3s;
        }
    }
    &.gh-button-remove {
        background: #ed5e68;
        color: white;
    }
    &.gh-button-secondary {
        background: $gh-color-secondary;
        color: white;
    }
    &.gh-button-secondary:hover {
        background: $gh-color-secondary-darker;
        color: white;
    }
    &.gh-button-tertiary {
        background: $gh-color-tertiary;
        color: $gh-color-secondary;
    }
    &.gh-button-tertiary:hover {
        background: $gh-color-tertiary-darker;
        color: $gh-color-secondary;
    }
    &.gh-button-4 {
        background: $gh-color-4;
        color: $gh-color-secondary;
    }
    &.gh-button-like {
        background: $gh-color-like;
        color: white;
    }
    mat-icon {
        color: white !important;
    }
}

.gh-stroked-button {
    border-radius: 8px !important;
    &.gh-stroked-button-primary {
        color: $gh-color-primary;
        mat-icon {
            color: $gh-color-primary !important;
        }
        &.with-colored-border {
            border: 1px solid $gh-color-primary !important;
        }
    }
    &.gh-stroked-button-warning {
        color: $gh-color-warning;
        mat-icon {
            color: $gh-color-warning !important;
        }
        &.with-colored-border {
            border: 1px solid $gh-color-warning !important;
        }
    }
    &.gh-stroked-button-4 {
        color: $gh-color-4;
        &.with-colored-border {
            border-color: $gh-color-4 !important;
        }
    }
}

.gh-action-button {
    height: 50px;
    border: solid 3px lightgray !important;
    border-radius: 8px !important;
    button {
        padding-top: 0.25rem;
    }
    p {
        animation: typing 4s steps(12) infinite;
        padding-top: 4px;
        color: $gh-color-primary;
        font-size: 1.5rem;
        font-weight: bold;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }
}

.gh-action-button:hover p {
    animation: none;
}

@keyframes typing {
    0% {
        width: 0;
    }
    50% {
        width: 100%;
    }
    55%,
    65%,
    75% {
        opacity: 1;
    }
    60%,
    70% {
        opacity: 0;
    }
    80% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}