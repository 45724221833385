@import "../styles/colors.scss";
@import "@angular/material/theming";
.ghErrorLabel {
    color: $gh-color-minor-warning;
}

// toolbar NOT in modal
.gh-toolbar {
    max-width: 100%;
    //margin-left: 2.5% !important;
    border-radius: 16px;
    //background: #EEEEEE !important;
    background: $gh-color-4 !important;
    &.gh-toolbar-secondary {
        color: white;
        background: $gh-color-secondary !important;
    }
    &.gh-toolbar-modal {
        max-width: 80%;
        margin-left: 10%;
    }
    span {
        margin: auto;
    }
}

mat-icon {
    // polish: come up with a better name for this, non-tab icons use this too
    &.tab-icon {
        margin-left: 4px;
        margin-right: 8px;
    }
    &.tab-icon-compound-right-side {
        font-size: 1.2rem;
        margin-left: -0.55rem;
        vertical-align: middle;
    }
    //? not sure why v2 is needed... some icons are larger than others? whatever, it works
    &.tab-icon-compound-right-side-v2 {
        font-size: 1rem !important;
        margin-left: -0.55rem;
        vertical-align: top;
    }
}

.mat-expansion-indicator::after {
    height: 15px;
    width: 15px;
}

// TABS
mat-tab-group {
    mat-tab-label {
        font-size: 8px;
        @media (min-width: 768px) {
            font-size: 16px;
        }
    }
    &.gh-notification-tabs {
        .mat-tab-label {
            color: white !important;
        }
    }
    &.gh-account-tabs {
        .mat-tab-label {
            color: black !important;
        }
    }
}

//Checkboxes
.gh-checkbox-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    flex-flow: row;
    .mat-checkbox .mat-checkbox-frame {
        border-color: $gh-color-secondary !important;
    }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: $gh-color-secondary !important;
    }
}

.mat-checkbox {
    margin-left: 20px;
}

.gh-checkbox-vertical {
    flex-flow: column;
}

// TOOLTIP
.mat-tooltip {
    font-size: 1.2rem !important;
}

// LABEL
.gh-mat-label {
    font-size: 1.2rem !important;
    color: $gh-color-secondary;
}

// ACCORDION
.gh-flat-accordion {
    .gh-expansion-panel {
        @include mat-elevation(0);
        border: 2px solid $gh-gray;
        border-radius: 16px;
    }
}

.gh-panel-header {
    mat-panel-title {
        padding-top: 0.75rem;
        &.centered {
            display: flex;
            justify-content: center;
        }
    }
    mat-panel-description {
        padding-top: 0.75rem;
        &.centered {
            display: flex;
            justify-content: center;
        }
    }
    // TODO: below not working, trying to center the arrow icon vertically
    // .mat-expansion-panel-header-title .material-icons{
    //   color: green !important;
    // }
}

// DRAG DROP
.ghDisabledDragDropCell {
    background: #cccccc !important;
    cursor: no-drop !important;
}

// STEPPER
.gh-new-user-stepper {
    position: relative;
    min-height: 20rem;
    .mat-horizontal-stepper-header-container {
        // border: solid 1px red;
        .mat-horizontal-stepper-header .mat-step-label {
            padding: 0px 0px 16px !important;
            color: $gh-color-primary !important;
        }
        .mat-step-icon {
            background: $gh-color-primary;
            font-size: 100px !important;
        }
        .mat-step-icon-state-done {
            background: $gh-color-success;
        }
        .mat-step-icon-selected {
            // background: green;
        }
    }
    .action-button-container {
        width: 100%;
        position: absolute !important;
        bottom: 1rem;
        .next-button {
            position: absolute;
            right: 5rem;
        }
    }
}

// MAT-FORM-FIELD
.gh-form-field {
    width: 100%;
    mat-select-trigger {
        mat-icon {
            vertical-align: bottom;
            margin-right: 1rem;
        }
    }
    // gina blind toggle here
    font-size: 1.1rem !important;
    &.gh-form-field-w-90 {
        width: 90%;
    }
    &.gh-form-field-w-95 {
        width: 95%;
    }
    &.gh-form-field-w-75 {
        width: 75%;
    }
    // OUTLINE
    &.gh-form-field-outline {
        .mat-form-field-appearance-outline,
        .mat-form-field-outline {
            color: $gh-color-4 !important;
            // opacity: 1!important;
        }
        //! not working
        // .mat-input-element {
        //     caret-color: $gh-color-4 !important;
        // }
        .mat-form-field.mat-focused,
        .mat-form-field-label {
            color: $gh-color-primary !important;
        }
    }
    // WHITE
    &.gh-form-field-white {
        mat-label {
            color: white !important;
        }
        .mat-form-field-underline {
            background-color: white !important;
        }
        .mat-form-field-ripple {
            background-color: white !important;
        }
        // not working ???
        // .mat-form-field-placeholder {
        //   color: white !important;
        // }
        // .mat-focused .mat-form-field-placeholder {
        //   color: white !important;
        // }
        // SELECT
        .mat-select-arrow,
        .mat-select-underline {
            color: white !important;
        }
        .mat-form-field-value-text {
            color: white !important;
        }
        .mat-select-value-text {
            color: white !important;
        }
        // DATE PICKER
        .mat-datepicker-toggle {
            color: white !important;
        }
    }
}

// RADIO BUTTONS
.gh-radio-group-container {
    mat-label {
        //padding-bottom: -100px !important;
    }
    .gh-radio-group {
        .gh-radio-button {
            margin: 1px;
            //background: $gh-color-primary;
        }
        &.gh-radio-group-vertical {
            display: flex;
            flex-direction: column;
            margin: 3px 0;
        }
        // WHITE
        &.gh-radio-group-white {
            .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: white;
                }
                .mat-radio-inner-circle {
                    background: white;
                }
            }
        }
    }
}

// DIVIDER
.gh-divider {
    // WHITE
    &.gh-divider-white {
        border-top-color: rgba(255, 255, 255, 0.3) !important;
    }
}

.gh-white-checkbox-container {
    .checkbox-text {
        margin-left: 0.5rem;
        height: 24px;
        line-height: 24px; // not working...
    }
    .slider-toggle-checkbox {
        margin-left: -0.8rem;
    }
    .mat-checkbox .mat-checkbox-frame {
        width: 24px;
        height: 24px;
        border-color: white;
        &.slider-toggle-checkbox {
            margin-left: -2rem;
        }
    }
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        width: 24px;
        height: 24px;
        background-color: transparent !important;
        font-size: 2rem;
    }
}

// MAT_CARD_SUBTITLE
.gh-subtitle-bold {
    font-weight: bold;
}

mat-error {
    color: red !important;
}

// MAT ERROR
// use this when error doesn't work inside of form field
.gh-mat-error-simulator {
    color: red;
    font-size: 0.8rem;
    //margin-top: -1.75rem;
    margin-top: -0.9rem;
}

// SECONDARY TOOLBAR
.gh-secondary-toolbar {
    //background: red !important;
    width: 88% !important;
    margin-left: 5.25% !important;
    border-radius: 16px;
    background: $gh-color-5 !important;
    .gh-secondary-toolbar-flex-container {
        width: 100%;
        height: 100%;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
    .mat-focused {
        .mat-form-field-label {
            color: red;
        }
    }
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: red !important;
    caret-color: red !important;
}

.mat-form-field-invalid .mat-form-field-underline {
    color: red !important;
    caret-color: red !important;
}