
@import '../styles/colors.scss';

.dot-revolution {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: $gh-color-tertiary;
    color: $gh-color-tertiary;
  }
  
  .dot-revolution::before, .dot-revolution::after {
    content: '';
    display: inline-block;
    position: absolute;
  }
  
  .dot-revolution::before {
    left: 5;
    top: -15px;
    width: 7px;
    height: 7px;
    border-radius: 3.5px;
    background-color: $gh-color-primary;
    color:$gh-color-primary;
    transform-origin: 5px 20px;
    animation: dot-revolution 0.7s linear infinite; 
  }
  
  .dot-revolution::after {
    left: 10;
    top: -40px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $gh-color-secondary;
  
    color: $gh-color-secondary;
    transform-origin: 5px 35px;
    animation: dot-revolution 1.5s linear infinite;
  }
  
  @keyframes dot-revolution {
    0% {
      transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
  }