@import "../../assets/styles/colors.scss";

.fc-toolbar-title,
.fc-col-header-cell-cushion,
.fc-daygrid-day-number,
.fc-event-title {
  color: $gh-color-primary;
  font-size: 1rem;
}

.fc-daygrid-event-dot,
.fc-list-event-dot {
  border-color: $gh-color-secondary !important;
  //font-size: 2rem;
}

.fc-event-time,
.fc-list-day-text,
.fc-list-day-side-text {
  color: $gh-color-primary;
}

.fc-event-title-container {
  background: $gh-color-primary;

  .fc-event-title {
    color: white;
  }
}

.fc-day-today {
  background: $gh-color-4 !important;
}
