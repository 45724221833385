@import "../styles/colors.scss";

.gh-edit-icon {
  color: $gh-color-edit;
}

.gh-delete-icon,
.gh-warning-icon {
  color: $gh-color-warning;
}

.gh-add-icon {
  color: $gh-color-success;
}

.gh-info-icon {
  color: $gh-color-info;
}

.gh-icon {
  // DEFAULTS
  vertical-align: middle;

  // SIZES
  &.gh-icon-smaller {
    font-size: 1.2rem;
    vertical-align: sub !important;
    line-height: 1.2rem;
  }

  &.gh-icon-larger {
    font-size: 2.2rem;
    vertical-align: sub !important;
    line-height: 2.5rem;
  }

  // COLORS
  &.neutral {
    color: gray;
  }
  &.like {
    color: green;
  }
  &.dislike {
    color: red;
  }
  &.gh-icon-primary {
    color: $gh-color-primary;
  }
  &.gh-icon-secondary {
    color: $gh-color-secondary;
  }
  &.gh-icon-white {
    color: white;
  }

  &.gh-icon-success {
    color: $gh-color-success;
  }

  &.gh-icon-warning {
    color: $gh-color-warning;
  }

  &.gh-icon-gray {
    color: #bdbdbd;
  }

  &.gh-icon-edit {
    color: $gh-color-edit;
  }

  // CURSOR
  &.gh-icon-clickable {
    cursor: pointer;

    &.ghIconClickableDisabled {
      opacity: 0.4;
      cursor: no-drop !important; // not working!
      pointer-events: none;
    }
  }

  &.gh-haven-i-manage-icon {
    color: $gh-color-gold;
  }

  &.gh-in-my-havens-icon {
    color: $gh-color-love;
  }

  // ANIMATIONS
  &.wiggle-icon {
    backface-visibility: hidden;
    transform: translateZ(0px);
    animation-duration: 5s;
    animation-name: wiggle;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes wiggle {
  2% {
    transform: translateX(3px) rotate(2deg);
  }
  4% {
    transform: translateX(-3px) rotate(-2deg);
  }
  6% {
    transform: translateX(3px) rotate(2deg);
  }
  8% {
    transform: translateX(-3px) rotate(-2deg);
  }
  10% {
    transform: translateX(2px) rotate(1deg);
  }
  12% {
    transform: translateX(-2px) rotate(-1deg);
  }
  14% {
    transform: translateX(2px) rotate(1deg);
  }
  16% {
    transform: translateX(-2px) rotate(-1deg);
  }
  18% {
    transform: translateX(1px) rotate(0deg);
  }
  20% {
    transform: translateX(-1px) rotate(0deg);
  }
}
