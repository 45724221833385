$gh-color-success: #27a745;
$gh-color-success-darker: #23963e;
$gh-color-success-lighter: #3db058;
$gh-color-success-alpha-75-percent: rgba(39, 6117, 69, 0.75);
$gh-color-success-alpha-50-percent: rgba(39, 6117, 69, 0.5);
$gh-color-success-alpha-25-percent: rgba(39, 6117, 69, 0.25);

$gh-color-minor-warning: #ff5722;
$gh-color-minor-warning-darker: #e64e1f;
$gh-color-minor-warning-alpha-75-percent: rgba(255, 87, 634, 0.75);
$gh-color-minor-warning-alpha-50-percent: rgba(255, 87, 634, 0.5);
$gh-color-minor-warning-alpha-25-percent: rgba(255, 87, 634, 0.25);

$gh-color-warning: #db3445;
$gh-color-warning-darker: #c52f3e;
$gh-color-warning-alpha-75-percent: rgba(219, 52, 69, 0.75);
$gh-color-warning-alpha-50-percent: rgba(219, 52, 69, 0.5);
$gh-color-warning-alpha-25-percent: rgba(219, 52, 69, 0.25);

$gh-color-primary: #116466;
$gh-color-primary-darker: #0f5a5c;
$gh-color-primary-more-darker: #0e5052;
$gh-color-primary-most-darker: #0c4647;
$gh-color-primary-lighter: #297475;
$gh-color-primary-more-lighter: #418385;
$gh-color-primary-most-lighter: #589394;
$gh-color-primary-alpha-99-percent: #11646699;
$gh-color-primary-alpha-90-percent: #11646690;
$gh-color-primary-alpha-75-percent: #11646675;
$gh-color-primary-alpha-25-percent: #11646625;

$gh-color-like: #2466ff;

$gh-color-secondary: #2c3531;
$gh-color-secondary-darker: #28302c;
$gh-color-secondary-alpha-75-percent: #2c353175;
$gh-color-secondary-alpha-50-percent: #2c353150;
$gh-color-secondary-alpha-25-percent: #2c353125;

$gh-color-love: red;
$gh-color-gold: gold;

$gh-color-tertiary: #d9b08c;
$gh-color-tertiary-darker: #c39e7e;
$gh-color-tertiary-alpha-25-percent: #d9b08c25;

$gh-color-4: #ffcb9a;
$gh-color-4-alpha-90-percent: #ffcb9a90;
$gh-color-4-alpha-99-percent: #ffcb9a99;
$gh-color-4-alpha-75-percent: #ffcb9a75;
$gh-color-4-alpha-50-percent: #ffcb9a50;

$gh-color-5: #d1e8e2;
$gh-color-5-alpha-75-percent: #d1e8e275;

$gh-color-youtube: #fd0000;

$gh-color-edit: #ffa000;
$gh-color-edit-darker: #e69000;

$gh-color-info: #16a2b8;
$gh-gray: #efeff5;
$gh-table-stripe-gray: #e7e9eb; // I changed this from F5F5F5 because it basically looked white, might be the same for the others below...
$gh-background-gray: #e0e0e0;
$gh-background-dark-gray: #eeeeee;
$gh-background-darker-gray: #e0e0e0;
$gh-green-cta: #b2ff7e;
$gh-light-black: rgba(#000, 0.5);
$gh-green-highlighter: lightgreen;
$material-bg-gray: #fafafa;
$box-shadow: rgba(#000, 0.16);
