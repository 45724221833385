.gh-image-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;

    #crop-button {
        min-width: 146px;
        padding: 0 !important;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0.5rem;

        .gh-icon {
            margin-left: 14px;
            margin-right: 4px;
        }
    }

    &.gh-image-upload-container-mobile {
        display: block;

        #crop-button {
            display: inline-block;
        }
    }

    .img-container {
        display: flex;
        justify-content: center;
        position: relative;

        img {
            display: block;
            max-height: 100px;
            max-width: fit-content;
            border-radius: 4px;
            margin-left: auto;
            margin-right: auto;
        }

        #delete-icon-container {
            background: $gh-color-warning;
            border-radius: 50%;
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow:
                0 10px 20px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.23);
            position: absolute;
            top: -12px;
            right: -12px;

            #delete-icon {
                color: white;
                font-size: 16px;
                margin-left: 6px;
                margin-top: 6px;
            }
        }

        &.img-container-mobile {
            margin-top: 2rem;
            #delete-icon-container {
                right: 175px;
            }
        }
    }

    .gh-file-upload-button-container {
        display: inline-block;
        input[type="file"] {
            display: none;
        }
        .gh-file-upload-button {
            font-family: Roboto, "Open Sans", sans-serif;
            font-weight: bold;
            border: 1px solid $gh-background-gray;
            border-radius: 8px;
            display: inline-block;
            padding: 6px 12px;
            color: $gh-color-primary;
            cursor: pointer;

            &.imageIsSelected {
                color: $gh-color-edit;
            }

            .gh-icon {
                margin-right: 6px !important;
                color: $gh-color-primary;

                &.imageIsSelected {
                    color: $gh-color-edit;
                }
            }
        }
    }
}
