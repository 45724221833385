$WIP_COLOR: red;
// TODO: Leaving off here, but need to use these to adjust the sizes based on screen size...
.gh-sidebar-a {
    //line-height: 1rem;
}

.gh-sidebar-icon {
    //font-size: 1rem !important;
    &.list-item-wip,
    &.listItemWIP {
        color: $WIP_COLOR;
    }
}

.gh-sidebar-item-title {
    font-size: 0.8rem !important;
    margin-left: -0.25rem !important;

    &.list-item-wip,
    &.listItemWIP {
        color: $WIP_COLOR;
        text-decoration: line-through;
    }
}
.list-item-wip,
.listItemWIP {
    color: $WIP_COLOR;
    text-decoration: line-through;
}