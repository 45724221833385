.gh-link,
.ghLink {
  color: $gh-color-primary !important;
  text-decoration: underline;
  cursor: pointer;
}

.gh-bold {
  font-weight: bold;
}

.gh-italic {
  font-style: italic;
}

.gh-lg-subtitle,
.gh-lg-p {
  font-size: 1.3rem !important;

  &.margin-top-negative {
    margin-top: -1rem !important;
  }
}

.gh-md-subtitle {
  font-size: 1rem !important;
}

.gh-sm-title,
.ghSmTitle {
  font-size: 1.1rem !important;

  &.margin-top-negative {
    margin-top: -1rem !important;
  }
}

.gh-xsm-title {
  font-size: 1rem !important;
  line-height: 1rem;

  &.margin-top-negative {
    margin-top: -0.5rem !important;
  }
}

.gh-xxsm-title {
  font-size: 0.8rem !important;
  line-height: 0.8rem;

  &.margin-top-negative {
    margin-top: -1rem !important;
  }
}
