// not working so far, but not super important so moving on for now
// trying to make panel larger height-wise so that user can see more options at one time
.gh-mat-select-full-height {
    // background: red; // this works, but height of panel below does not
    .mat-select-panel {
        // min-height: 80vh !important;
        min-height: 3000px !important;
    }

    // ::ng-deep .mat-select-panel {
    //   min-height: 80vh !important;
    // }
}
