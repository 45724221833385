.gh-dialog,
.ghDialog {
    min-width: 60%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 1rem !important;
    //TOOLBAR
    .gh-dialog-header-container {
        width: 100%;
        margin-top: 1rem;
        .gh-dialog-header-toolbar,
        .gh-dialog-header-close-button {
            display: inline-block;
        }
        .gh-dialog-header-toolbar {
            min-height: 4rem;
            line-height: 4rem;
            width: 100%;
            border-radius: 16px;
            background: $gh-color-4 !important;
        }
        .gh-dialog-header-top-left-icon {
            margin: 0;
            padding-left: 0.75rem;
            font-size: 2.5rem;
            line-height: 1rem;
        }
        .gh-dialog-header-toolbar {
            width: 90%;
            margin-left: 4%;
            &.dialogHasTopLeftIcon {
                margin-left: 3%;
            }
            .gh-dialog-header-toolbar-flex-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .gh-dialog-header-toolbar-title {
                    font-size: 1.4rem;
                }
            }
        }
        .gh-dialog-header-close-button {
            margin-left: 1.5rem;
            outline: none;
            -webkit-transition: -webkit-transform 0.8s ease-in-out;
            transition: transform 0.4s ease-in-out;
        }
        .gh-dialog-header-close-button:hover {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
    // CONTENT
    .gh-dialog-content-container {
        margin-top: 1.5rem;
        min-height: 60vh; // needed so that action buttons are at the bottom (also for cup animation in play-now component)
        max-height: 75vh;
        max-width: 100%;
        min-width: 100%;
        overflow-y: scroll;
        padding: 2rem;
        animation: fadeIn 1s linear;
        -webkit-animation: fadeIn 1s linear;
        &.noActionButtons {
            padding-bottom: 0;
        }
        &.isAdmin {
            min-height: 90vh; // needed so that action buttons are at the bottom (also for cup animation in play-now component)
            max-height: 90vh;
        }
    }
    // DIVIDER
    mat-divider {
        // this made it too wide
        // width: 110%;
        // margin-left: -5%;
        // below not working to make it stretch to each edge...
        padding: 0 !important;
        margin: 0 !important;
    }
    // ACTIONS BUTTONS
    .gh-dialog-action-button-container {
        justify-self: flex-end;
        max-height: 2rem;
        width: 100%;
        position: relative;
        margin-bottom: 0.0625rem;
        #submit-button,
        #cancel-button,
        #left-action-button,
        #delete-button {
            position: absolute;
            bottom: 0rem;
        }
        #submit-button {
            right: 2rem;
        }
        #cancel-button {
            right: 15rem;
        }
        #left-action-button {
            left: 2rem;
        }
        #delete-button {
            left: 15rem;
        }
    }
    &.gh-dialog-mobile,
    &.ghDialogMobile,
    &.gh-dialog-full-screen {
        width: 100%;
        min-width: 100%;
        // margin: 0;
        mat-dialog-container {
            min-width: 100% !important; //! doesnt cover width and VW just makes things worse. need to rework cascading-ness once we have time
            padding-left: 2px !important;
            padding-right: 2px !important;
        }
        .gh-dialog-header-container {
            margin-top: 0;
            .gh-dialog-header-toolbar {
                width: 80%;
                margin-left: 5%;
                min-height: 2.5rem;
                line-height: 2.5rem;
                .gh-dialog-header-toolbar-flex-container {
                    .gh-dialog-header-toolbar-title {
                        font-size: 1rem !important;
                    }
                }
            }
            .gh-dialog-header-close-button {
                margin-left: 0.5rem !important;
            }
        }
        .gh-dialog-content-container {
            // background: red;
            padding: 0 !important;
            // max-height: 75vh;
            overflow-y: scroll;
            margin-top: 0;
            // min-height: 60vh; // needed so that action buttons are at the bottom (also for cup animation in play-now component)
            max-width: 100%;
            min-width: 100%;
        }
        &.gh-dialog-full-screen-no-buttons {
            .gh-dialog-content-container {
                margin-top: 0;
                min-height: 100vh;
            }
        }
        .gh-dialog-action-button-container {
            max-height: 1rem;
            margin-bottom: 0px !important;
            #submit-button,
            #cancel-button,
            #left-action-button,
            #delete-button {
                position: absolute;
                bottom: 1.5rem;
            }
        }
    }
}