@import "@angular/material/theming";


// ELEVATION NOT WORKING
// see: md-event-detail (currently using mat-elevation-z12 (works), gh-card gh-card-z12 (should work but doesnt))

.gh-card {
    border-radius: 8px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    box-sizing: border-box;

    // &.gh-card-12 {
    //     @include mat-elevation(12);
    // }
  
    .gh-card-header {
      border-bottom: 1px solid $gh-gray;
      align-items: center;
  
      mat-card-title {
        font-size: 16px;
        font-weight: bold;
        color: $gh-light-black;
        text-transform: capitalize;
      }
    }
  }
  