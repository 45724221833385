/* Container */

.gh-datetime-input-container {
    position: relative;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
    max-width: 94%;
}


/* Input */

.gh-datetime-input-container input[type="datetime-local"] {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 16px;
    width: 100%;
    outline: none;
    padding: 10px 0;
    background-color: transparent;
    transition: all 0.3s;
}

.gh-datetime-input-container input[type="datetime-local"]:focus {
    border-bottom: 2px solid #6200ea;
    /* Accent color, can be changed */
    padding-bottom: 9px;
}


/* Label (assuming you add one) */

.gh-datetime-input-container label {
    position: absolute;
    left: 0;
    top: 10px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s;
    pointer-events: none;
}

.gh-datetime-input-container input[type="datetime-local"]:focus+label,
.datetime-input-container input[type="datetime-local"]:not(:placeholder-shown)+label {
    top: -20px;
    font-size: 12px;
    color: #6200ea;
    /* Accent color, can be changed */
}


/* Optional: Ripple effect approximation */

.gh-datetime-input-container input[type="datetime-local"]:focus {
    box-shadow: 0 0 0px 1000px rgba(98, 0, 234, 0.1) inset;
}