// not working...

.gh-scrollbar-white-container {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: green;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: blue;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: red;
  }
}
