.disabledSliderContainer {
  filter: grayscale(100%);
  cursor: no-drop;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: $gh-color-primary !important;
}
.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: $gh-color-tertiary;
  border: solid 2px $gh-color-secondary;
  bottom: -20px;
  right: -20px;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.mat-slider-thumb{
  background-color: $gh-color-tertiary !important;
  border: solid 2px $gh-color-tertiary !important;
}

.mat-slider-thumb-label-text{
  background-color: $gh-color-primary !important;
  border: solid 2px $gh-color-primary !important;
  color: white !important;
}

.mat-slider-focus-ring{
  background-color: $gh-color-primary !important;
  border: solid 2px $gh-color-primary !important;
}

.mat-slider-thumb-label{
  background-color: $gh-color-primary !important;
  border: solid 2px $gh-color-primary !important;
}

.ql-container {
  height: 300px !important;
  width: 300px !important;
}


