@import "./assets/styles/material-theme.scss";
@import "./assets/styles/colors.scss";
@import "./assets/styles/mat-overrides.scss";
@import "./assets/styles/snackbars.scss";
@import "./assets/styles/buttons.scss";
@import "./assets/styles/icons.scss";
@import "./assets/styles/event-builder.scss";
@import "./assets/styles/mat-panels.scss";
@import "./assets/styles/vertical-tab-components.scss";
@import "./assets/styles/fonts.scss";
@import "./assets/styles/keyframe-animations.scss";
@import "./assets/styles/neumorphism-elements.scss";
@import "./assets/styles/filters.scss";
// @import "./assets/styles/slider.scss";
@import "./assets/styles/scrollbar.scss";
@import "./assets/styles/refine-search.scss";
@import "./assets/styles/cards.scss";
@import "./assets/styles/cropper.scss";
@import "./assets/styles/font-styles.scss";
@import "./assets/styles/three-dots.scss";
@import "./assets/styles/ratings.scss";
@import "./assets/styles/calendar.scss";
@import "./assets/styles/gh-image-upload.scss";
@import "./assets/styles/gh-elevation.scss";
@import "./assets/styles/sidebar.scss";
@import "./assets/styles/toggle-buttons.scss";
@import "./assets/styles/mat-slider.scss";
@import "./assets/styles/map.scss";
@import "./assets/styles/gh-dropdown-select.scss";
@import "./assets/styles/gh-datetime-picker.scss";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "../node_modules/three-dots/dist/three-dots.css";
@import "./assets/styles/gh-dialog";
@import "./assets/styles/gh-dialog-backdrop";
@import "@angular/material/theming";
@import "~bootstrap/dist/css/bootstrap.css";


@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';


@import "~nouislider/distribute/nouislider.min.css";
//!!!!!!!!!!!!!!!!!!!!!!!!! @import '~ngx-bar-rating/themes/br-default-theme';
// @import '~ngx-bar-rating/themes/br-default-theme';
// @import '~ngx-bar-rating/themes/br-horizontal-theme';
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// $app-primary: mat-palette($mat-light-blue);
// $app-accent: mat-palette($mat-light-blue);
// $app-warn: mat-palette($mat-red);
// // $theme-dark: mat-dark-theme($app-primary, $app-accent, $app-warn-warn);
// $theme-dark: mat-dark-theme($app-primary, $app-accent, $app-warn);
// $theme-light: mat-light-theme($app-primary, $app-accent, $app-warn);
// .color-scheme-dark {
//   @include angular-material-theme($theme-dark);
// }
// .color-scheme-light {
//   @include angular-material-theme($theme-light);
// }
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Open Sans", sans-serif;
}

.max-w-100 {
    max-width: 100%;
}

.gh-container,
.ghContainer {
    margin: 2rem;
    max-width: 80vw;
    // &.no-card, &.noCard {
    //   mat-card {
    //     background: $material-bg-gray;
    //     @include mat-elevation(0); // NOTE::: to accomplish this, needed to import @angular/material/themeing
    //   }
    // }
}

.ghMobileContainer {
    margin: 1rem;
    max-width: 100%;
}

.gh-section {
    margin: 0 0 2rem 0;
    background-color: $gh-background-gray;
    border-radius: 16px;
    padding: 2rem 0;
}

.gh-form {
    .gh-form-modal {
        min-width: 50vw;
        max-height: 80vh;
    }
    .gh-select-wrapper {
        .mat-form-field-underline {
            bottom: 16px;
        }
    }
    section,
    .gh-section {
        margin: 0 0 2rem 0;
        background-color: $gh-background-gray;
        border-radius: 16px;
        padding: 2rem 0;
    }
    .mat-form-field {
        margin-top: 1rem;
        margin-left: 2%;
        width: 96%;
    }
    .mat-card-title {
        font-size: 16px !important;
        font-style: bold;
        margin-left: 2%;
        margin-top: 2rem;
    }
    .mat-card-subtitle {
        margin-left: 2rem;
    }
}

.gh-accordion {
    mat-expansion-panel-header {
        min-height: 70px !important;
        mat-panel-title {
            margin-top: 1rem;
        }
        .header-img {
            border-radius: 16px;
            max-height: 150px;
            max-width: 100px;
        }
    }
    img {
        margin: 1rem 0;
        border-radius: 16px;
        max-height: 300px;
        max-width: 200px;
    }
    .img-wrapper {
        &.no-border-radius {
            border-radius: 0;
        }
    }
    .title {
        color: #202020;
    }
    .header-span-bold {
        font-weight: bold;
        font-size: 1.2rem;
        color: #202020;
    }
    mat-panel-title,
    .header-span {
        display: block;
    }
    mat-panel-description {
        display: flex;
        align-items: center;
    }
    .header-description-span {
        display: flex;
        justify-content: flex-start;
        align-content: center;
    }
    .content-group {
        padding-left: 1rem;
        margin-bottom: 2rem;
    }
    @media (max-width: 768px) {
        margin-top: 1rem;
        .header-span {
            font-size: 16px;
        }
        mat-panel-description {
            .header-span {
                font-size: 12px;
                padding-top: 1rem;
            }
        }
    }
}

.gh-tag-list-item {
    .tag-group {
        margin-bottom: 2rem;
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        border-color: #c7c7c7;
        padding: 10px 30px;
        min-height: 8rem;
        .mat-chip-list {
            margin-top: -0.75rem;
        }
    }
}

.gh-content-group {
    margin-bottom: 2rem;
    .detail {
        margin-top: -1rem;
    }
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    flex-basis: 0;
}

.inline-span {
    display: inline-block;
    &.inline-span-align-right {
        float: right;
    }
}

// #close-button {
//   margin: 1rem 2rem 1rem auto;
//   outline: none;
//   mat-icon {
//     -webkit-transition: -webkit-transform 0.8s ease-in-out;
//     transition: transform 0.4s ease-in-out;
//   }
// }
// #close-button:hover {
//   mat-icon {
//     -webkit-transform: rotate(180deg);
//     transform: rotate(180deg);
//   }
// }
.data-group {
    max-width: 100%;
    min-height: 16rem;
    background-color: #eeeeee;
    border-radius: 12px;
    padding: 2rem 0;
    &.data-group-no-padding {
        padding: 0;
    }
    &.data-group-no-min-height,
    &.dataGroupNoMinHeight {
        min-height: 0 !important;
    }
    &.data-group-has-header {
        padding-top: 0.75rem;
    }
    .gh-data-group-header {
        width: 100%;
        mat-card-title {
            padding-left: 1rem;
        }
    }
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.pinkBox {
    height: 100px;
    width: 100px;
    background-color: pink;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.bioPanelClosed {
    mat-expansion-panel {
        //background: $gh-color-primary;
        box-shadow: none !important;
    }
    .mat-expansion-indicator:after {
        //color: white !important;
    }
    p {
        //color: white;
    }
}

.bioPanelClosedMyProfile {
    mat-expansion-panel {
        background: $gh-color-primary;
        box-shadow: none !important;
        margin-top: 3rem;
    }
    .mat-expansion-indicator:after {
        color: white !important;
    }
    p {
        color: white;
    }
}

.descriptionPanelClosedEvents {
    mat-expansion-panel {
        box-shadow: none !important;
        margin-top: 3rem;
    }
    // .mat-expansion-indicator:after {
    //   color: white !important;
    // }
    // p {
    //   color: white;
    // }
}

.bioPanelClosedFriendsGh {
    mat-expansion-panel {
        background: $gh-color-tertiary;
        box-shadow: none !important;
    }
    .mat-expansion-indicator:after {
        color: $gh-color-secondary !important;
    }
    p {
        color: $gh-color-secondary;
    }
}

// also need this outside of gh-dialog since some modals
// (smaller width, at least until I figure out the UI issue)
//  dont use gh-dialog
.gh-dialog-actions {
    justify-content: flex-end;
    align-content: end;
    padding: 15px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 25px;
    @media (max-width: 768px) {
        //! NOT WORKING, TRYING TO MINIMIZE SIZE
        bottom: 5px;
    }
}

.t-dialog {
    mat-dialog-container {
        background: transparent !important;
    }
}

// SEARCH CONTAINER
.gh-search-container {
    max-width: 90%;
    margin-left: 5%;
    .input-field {
        margin-top: 2rem;
        position: relative;
        width: 100%;
        height: 44px;
        line-height: 24px;
    }
    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        color: #d3d3d3;
        transition: 0.2s all;
        cursor: text;
    }
    input {
        width: 130px;
        -webkit-transition: width 0.4s ease-in-out;
        transition: width 0.4s ease-in-out;
        border: 0;
        outline: 0;
        padding: 0.5rem 0;
        border-bottom: 2px solid #d3d3d3;
        box-shadow: none;
        color: #111;
    }
    input:invalid {
        outline: 0;
    }
    input:focus {
        width: 100%;
        border-color: $gh-color-secondary;
    }
    input:focus ~ label,
    input:valid ~ label {
        font-size: 14px;
        top: -24px;
        color: $gh-color-secondary;
    }
}

.gh-textarea-container {
    width: 90%;
    margin-left: 5%;
    padding: 1rem !important;
    border: 2px solid $gh-background-gray !important;
    border-radius: 16px;
}

.carousel-small {
    .carousel-cell,
    .carousel-cells {
        img {
            display: block !important;
            max-width: 100% !important;
            max-height: 100% !important;
            width: auto !important;
            height: auto !important;
        }
    }
}

.gh-flex {
    display: flex;
    &.gh-flex-center-x {
        justify-content: center;
    }
    &.gh-flex-center-y {
        align-items: center;
    }
}

.gh-bordered-textarea-container {
    border: solid 1px $gh-background-gray;
    border-radius: 16px;
    padding: 1rem;
    &.white-border {
        border-color: white;
    }
}

.gh-spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gh-card-header-row {
    &.has-button {
        min-height: 3rem;
    }
    mat-card-title {
        margin-top: -0.25rem;
    }
    .gh-header-row-item-right {
        float: right;
    }
    .gh-button {
        margin-top: -10px !important;
        margin-bottom: 10px;
    }
    .mat-divider {
        width: 100%;
        margin-top: -0.25rem;
    }
}

// .tippy-box[data-theme~="status-update-logged-play-players"] {
//   background-color: #fff;
//   color: black;
//   box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
//   padding: 15px;
//   width: 100%;
//   .tippy-arrow {
//     color: #fff !important;
//   }
// }
// .tippy-box[data-theme~="event-organizers"] {
//   background-color: #fff;
//   color: black;
//   box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
//   padding: 15px;
//   width: 100%;
//   .tippy-arrow {
//     color: #fff !important;
//   }
// }
.gh-image-gallery-box-image {
    max-height: 50px;
}

.gh-loading-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeSlider {
    .mat-slider-thumb-label {
        transform: rotate(45deg) !important;
        border-radius: 50% 50% 0 !important;
    }
    .mat-slider-thumb {
        transform: scale(0) !important;
    }
    .mat-slider-thumb-label-text {
        opacity: 1 !important;
    }
}

// TODO: move this into its own file?
//  todo ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$star-size: 18px;
.star-icons-container {
    button {
        padding: 0 !important;
        margin: 0 !important;
        mat-icon {
            //! approach 1
            // works but doesn't limit margin/padding
            // height: $star-size !important;
            // width: $star-size !important;
            // font-size: $star-size !important;
            //! approach 2
            // works but doesn't limit margin/padding
            // transform: scale(0.5);
        }
    }
}

//  todo ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  todo ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-body.mat-tab-body-active {
    // Add your custom styles here
    // background-color: red !important;
    // color: white !important;
    overflow-y: hidden !important;
}
