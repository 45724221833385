@import "../styles/colors.scss";
.gh-refine-search {
  height: 25rem; // determines vertical scroll
  overflow-y: scroll;

  mat-card-subtitle {
    color: white !important;
  }

  ul {
    list-style-type: none;
    margin-top: 4px;
  }

  .section {
    max-width: 95%;
    margin-left: 2.5%;

    .gh-slider-container {
      width: 100%;
      height: 100%;
    }

    .clickable {
      cursor: pointer !important;
    }
  }

  .section:nth-child(odd) {
    background: #2b7e80 !important; // gh-color-primary hex lightened by 10%
  }
}
