@keyframes nudge {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes rotateOpen {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(1080deg);
    }
  }
  
  @keyframes rotateClose {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(-1080deg);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  

  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1.4);
    }
  }