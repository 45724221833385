.event-tile {
    // width breaks when event more than 1 column
    // width: 26rem !important;
    height: 5rem !important;
  }
  
  .header-tile {
    color: white;
    background: $gh-color-secondary !important;
    border-radius: 8px;
  }
  
  .room-tile {
    color: $gh-color-secondary;
    padding-top: 5rem !important;
  }