@import '../styles/colors.scss';

// NOTE:
//     font-size not working!!!

.ghSuccessSnackbar {
  z-index: 1530000 !important; // needs to be > than FAB NOT WORKING!!!!
  background-color: $gh-color-success !important;
  color: white !important;
  font-size: 24rem !important;
  // font-weight: bold;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ghErrorSnackbar {
  color: white !important;
  background-color: $gh-color-warning !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 100px !important;
}

.ghIconSnackbar {
  background: #fafafa !important;
  color: $gh-color-secondary !important;
  font-weight: bold;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
