@import '@angular/material/theming';
@include mat-core();
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
// IMPORTANT!:
// in addition to overriding mat components to our brand colors, we should set themes as well
//  once custom theme is established and working, create a dark them and swap between the two
// 
//   PROBABLY WORTH GETTING A UDEMY TUTORIAL FOR THIS!!!
//
//   RECOURCES THAT MIGHT HELP:
//     https://www.positronx.io/create-angular-material-8-custom-theme/
//     https://stackoverflow.com/questions/41440998/how-can-i-use-custom-theme-palettes-in-angular
//     http://mcg.mbitson.com/#!?primary=%23116466
//     https://stackoverflow.com/questions/48119179/custom-theme-in-material-2-returns-argument-map-of-map-getmap-key-mus/48124486
//     https://stackoverflow.com/questions/41440998/how-can-i-use-custom-theme-palettes-in-angular
//     https://github.com/angular/components/blob/master/guides/theming.md
// 
//   VIDEO: (I like the icon and theme selection from the nav bar!)
//     https://www.youtube.com/watch?v=f-kUmbmBVLo&t=75s&ab_channel=DesignCourse
//      
////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////

/* ======== Angular material custom themes ======== */

$my-custom-primary: mat-palette($mat-deep-purple);
$my-custom-accent: mat-palette($mat-pink, 100, 500, A100);
$my-custom-warn: mat-palette($mat-lime);
$my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);
@include angular-material-theme($my-custom-theme);