@import "../styles/colors.scss";

.gh-map-container {
    position: relative;
    height: 100%;
    min-height: 40rem;
    width: 100%;
    padding-right: 2rem;

    #map {
        height: 100%;
        width: 96% !important;
    }

    #menu {
        background: white !important;
        color: #ffffff !important;
        position: absolute;
        z-index: 1;
        top: 60px;
        right: 35px;
        border-radius: 8px;
        width: 120px;
        padding: 0.75rem 1.5rem;
        font-family: "Open Sans", sans-serif;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

        a {
            font-size: 13px;
            color: #ffffff;
            display: block;
            margin: 0 0 4px 0;
            padding: 0;
            padding: 10px;
            text-decoration: none;
            border-radius: 8px;
            text-align: center;
        }

        a:last-child {
            border: none;
        }

        a:hover {
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            transform: scale(0.9) !important;
        }

        a.active {

        }

        a.active:hover {
            transform: scale(1.1) !important;
        }
    }
}
